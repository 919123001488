import OverlaysManager from "../OverlayManager";
const tools = require('../tools');
import Entry from "./Entry";
import Swiper from "swiper";
import FilterForm from "./FilterForm";
import Lazy from "../Lazy";
import ViewListDL from "../datalayer/views/ViewListDL";
import ViewItemDL from "../datalayer/views/ViewItemDL";


class List {

    constructor(elt, scrollableWrapper) {
        this.elt = elt;
        this.scrollableWrapper = scrollableWrapper ? scrollableWrapper : window;
        this.id = this.elt.getAttribute('id');
        this.filterForm;
        this.entriesWrapper;
        this.entriesQty;
        this.entries = [];
        this.pager;
        this.updatePager = true;
        this.onUpdateFcts = [];
        this.init();
    }

    isCheckList() {return this.entriesWrapper.id === 'checklist';}
    isEntryClickAction () {return !this.isCheckList() && this.elt.classList.contains('titles-list') && !this.elt.classList.contains('single-entry')}

    onUpdate(fct) {
        this.onUpdateFcts.push(fct);
    }

    init() {
        if(this.elt.classList.contains('slider')) {

            const sliderElt = this.elt.querySelector('.list-slider');

            if(sliderElt) {
                new Swiper(sliderElt, {
                    speed: 400,
                    spaceBetween: 50,
                    navigation: {
                        nextEl: this.elt.querySelector('.slider-next'),
                        prevEl: this.elt.querySelector('.slider-prev')
                    },

                    breakpoints: {
                        320: {
                            slidesPerView: 2,
                            spaceBetween: 10
                        },

                        550: {
                            slidesPerView: 3,
                            spaceBetween: 10
                        },

                        750: {
                            slidesPerView: 4,
                            spaceBetween: 10
                        }
                    }
                })
            }
        } else {
            const filterFormElt = this.elt.querySelector('.filter-form');

            if (filterFormElt) {
                this.filterForm = new FilterForm(filterFormElt, this);
            }

            this.entriesWrapper = this.elt.querySelector('.list-wrapper');

            this.initChecklistBtn();

            if (this.entriesWrapper) {
                this.addAllEntries(this.entriesWrapper.querySelectorAll('.entry'));
            }

            this.entriesQty = this.elt.querySelector('#entry-amount');

            this.pager = this.elt.querySelector('#pager-' + this.id);
            if(this.pager) {
                this.initListPager();
            }

            this.initDisplaySwitch();
        }

    }

    initChecklistBtn() {
        let checkListInput = document.querySelector('#checklist-input');
        tools.each(this.elt.querySelectorAll('.checklist-btn'), btn => {
            btn.addEventListener('click', (e) => {
                e.preventDefault();
                if(this.isCheckList()) {
                    this.entriesWrapper.id='';
                    if(checkListInput) {
                        checkListInput.value = 'false';
                    }

                    this.update(tools.removeParam(tools.getCurrentURL(), 'checklist'), true);
                    btn.querySelector('a').textContent = 'Checklist aktivieren';

                } else {
                    this.entriesWrapper.id='checklist';
                    if(checkListInput) {
                        checkListInput.value = 'true';
                    }

                    this.update(tools.addParam(tools.getCurrentURL(), 'checklist', 'true'), true);
                    btn.querySelector('a').textContent = 'Checklist deaktivieren';
                }
            })
        });
    }

    isGridView() {
        return this.elt.classList.contains('tiles-v');
    }

    removeAllEntries() {
        this.entriesWrapper.style.height = this.entriesWrapper.offsetHeight + 'px';
        while (this.entriesWrapper.firstChild) {
            this.entriesWrapper.removeChild(this.entriesWrapper.firstChild);
        }
    }

    addAllEntries(entriesElt, display) {
        if (entriesElt && entriesElt.length > 0) {
            const entryClickAction = (e, entry) =>  {

                if(!e.target.classList.contains('proceed')) {
                    e.preventDefault();
                    e.stopPropagation();
                    //Close Shoppingcart
                    document.getElementById("shoppingCart")?.classList.remove("show")
                    if (this.isGridView()) {
                        if (entry.active) {
                            entry.deactivate();
                        } else {
                            tools.executeDataLayerBy("views", new ViewItemDL(entry.elt))
                            let prevActiveEntry = [];
                            tools.each(this.entries, ent => {
                                if(ent.active && !ent.equals(entry)) {
                                    prevActiveEntry.push(ent);
                                }
                            });

                            entry.activate(prevActiveEntry);
                        }
                    }
                }
            };

            tools.each(entriesElt, (entryelt, index) => {

                const entry = new Entry(index, entryelt, this.elt, this.scrollableWrapper);

                this.entries.push(entry);
                if (display) {
                    this.entriesWrapper.insertAdjacentElement('beforeend', entryelt)
                }

                if (this.isEntryClickAction()) {
                    entry.bookBox.addEventListener('click', e => entryClickAction(e, entry))
                }

                if(entry.paramMatch) entry.activate();
            });
        }


        this.entriesWrapper.style.height = 'auto';
        new Lazy();
    }

    updateEntriesQty(newQty) {
        if(newQty) {
            newQty = newQty.split(',').join('');
            const currentQty = this.entriesQty.textContent.split(',').join('') * 1;
            newQty = newQty * 1;

            const updateVal = newVal => {
                this.entriesQty.textContent = newVal;
            };
            tools.easeTo(currentQty, newQty, updateVal, 700);
        }
    }

    update(url, ignoreForm=false) {
        OverlaysManager.showLoadingAnim();
        this.removeAllEntries();
        let targetUrl = url ? url : tools.getCurrentURL(true);

        window.setTimeout(() => {
            tools.ajxGet(targetUrl,
                ignoreForm || !this.filterForm ? null
                                    : new FormData(this.filterForm.elt),
                (response) => {
                    const newList = response.querySelector('#' + this.id);
                    if(newList) {
                        tools.executeDataLayerBy("views", new ViewListDL(newList))
                        const entries = newList.querySelectorAll('.list-wrapper .entry');

                        if (entries && entries.length > 0) {

                            this.elt.classList.remove('noresult');

                            const entriesAmount = newList.querySelector('#entry-amount');

                            if(entriesAmount) {
                                this.updateEntriesQty(entriesAmount.textContent);
                            }

                            if (entries) {
                                this.addAllEntries(entries, true);
                            }

                        } else {
                            this.elt.classList.add('noresult');
                        }

                        const listPager = newList.querySelector('#pager-' + this.id);
                        if(listPager) {
                            this.initListPager(listPager);
                        }

                        tools.each(this.onUpdateFcts, fct => fct());
                    } else {
                        this.elt.classList.add('noresult');
                    }
                    OverlaysManager.removeLoadingAnim();
                }, 'document')
        }, 200)
    }


    initDisplaySwitch() {
        const displayBtn = this.elt.querySelectorAll('.display-btn');
        if (displayBtn) {
            const viewClasses = [];
            tools.each(displayBtn, btn => {
                const viewClass = btn.getAttribute('data-viewclass');
                if (viewClass) {
                    viewClasses.push(viewClass);
                    btn.addEventListener('click', e => {
                        tools.each(viewClasses, c => this.elt.classList.remove(c));
                        this.elt.classList.add(viewClass);
                        tools.each(this.entries, ent => ent.deactivate());
                    })
                }
            })
        }
    }

    initListPager(newPager) {
        if(newPager) {
            this.pager.innerHTML = newPager.innerHTML;
        }

        const pagerClickAction = (e, a)=> {
            e.preventDefault();
            e.stopPropagation();
            a.parentNode.classList.add('active');
            tools.scrollTo(window, this.filterForm.elt.offsetTop, 400);
            this.update(a.getAttribute('href'));
        };

        tools.each(this.pager.querySelectorAll('a'), a => {
            a.addEventListener('click', e => pagerClickAction(e,a));
        });
    }
}



export default List;
