import DataLayerEvent from "./DataLayerEvent";

class Handler {
    constructor() {
        this.active = true;
    }

    init() {
        throw new Exception('Should not be executed directly');
    }

    update() {
        throw new Exception('Should not be executed directly');
    }

    deactivate() {
        this.active = false;
    }

    isActive() {
        return this.active;
    }

    execute(dle = new DataLayerEvent(), event) {
        if(this.active) {
            if(window.debugdl && event) {
                event.stopPropagation();
                event.preventDefault();
            }
            dle.execute(event);
            if(window.debugdl) {
                return false;
            }
        }
    }
}

export default Handler;

