
const tools = {
    doOnWindowResizeList: [],
    device: null,

    dataLayerHandler: null,

    initDataLayer(dlh) {
        this.dataLayerHandler = dlh;
        this.dataLayerHandler.init();
    },

    updateDataLayer() {
        if(this.dataLayerHandler) {
            this.dataLayerHandler.update();
        }
    },

    deactivateDataLayer() {
        if(this.dataLayerHandler) {
            this.dataLayerHandler.deactivate();
        }
    },

    executeDataLayerBy(handlerName, dataLayerEvent, event) {
        if(this.dataLayerHandler) {
            this.dataLayerHandler.executeBy(handlerName, dataLayerEvent, event);
        }
    },

    hasParameter(name) {
        return window.location.search.indexOf('?' + name) !== -1 || window.location.search.indexOf('&' + name) !== -1;
    },

    ajxGet(url, formData = null, fallback = null, responseType = 'text', replaceHistory = true, errorHandler = null) {
        let req = null;
        if (url) {
            req = new XMLHttpRequest();
            req.onreadystatechange = function () {
                if (this.readyState === XMLHttpRequest.DONE) {
                    let status = req.status;
                    if (status === 0 || (status >= 200 && status < 400)) {
                        if (fallback) {
                            fallback(req.response);
                        }
                    } else {
                        if (errorHandler) {
                            errorHandler(req.response)
                        }
                    }
                }
            };

            if (formData && formData instanceof FormData) {

                url += url.indexOf('?') === -1 ? '?' : '&';
                for (let entry of formData.entries()) {
                    url += entry[0] + '=' + entry[1] + '&';
                }
                url = url.substr(0, url.length - 1);
            }

            if (replaceHistory) {
                tools.replaceHistoryState(url);
            }

            req.responseType = responseType;
            req.open('GET', url, true);
            req.send(null);
        } else {
            fallback({error: 'url not provided'})
        }
        return req;
    },

    each(list, fn) {
        if (list && fn) {
            for (let i = 0; i < list.length; i++) {
                fn(list[i], i);
            }
        }
    },

    getCurrentURL(excludeParams) {
        let url = window.location.href;
        if (excludeParams) {
            let i = url.indexOf("?");
            if (i > -1) {
                url = url.substring(0, i);
            }
            let j = url.indexOf("#");
            if (j > -1) {
                url = url.substring(0, j);
            }
        }
        return url;
    },

    getParameterByName(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        let regex = new RegExp("[\\?&]" + name + "=([^&#]*)"), results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    },

    addParam(url, param, value) {
        let cleanedUrl = tools.removeParam(url, param);
        return cleanedUrl + (cleanedUrl.indexOf('?') !== -1 ? '&' : '?') + param + '=' + value;
    },

    removeParam(url, paramName) {
        let modifiedUrl = url;

        if(url.indexOf('?') !== -1) {
            let urlArray = url.split('?');
            modifiedUrl = urlArray[0] + tools.removeParamFromQueryPart(urlArray[1], paramName);
        }

        return modifiedUrl;
    },

    removeParamFromQueryPart(urlQuery, param) {
        if(urlQuery.indexOf('?') === 0) {
            urlQuery = urlQuery.substr(1);
        }
        let params = urlQuery.split("&");
        return params.reduce((c, cur) => {
            if (cur.indexOf(param) !== 0) {
                c += (c.length > 0 ? '&' : '?') + cur;
            }
            return c;
        }, '');
    },

    isEditMode() {
        return document.body.classList.contains('editmode')
    },

    scrollTo(elt, destination, duration = 300, fb) {

        if(destination > 100) {
            tools.freezeNav();
        }
        let start, distance, timeStart;

        function proceed() {
            start = elt === window ? (window.pageYOffset || window.scrollY) : elt.scrollTop;
            distance = destination - start;
            timeStart = null;
            requestAnimationFrame(loop);
        }

        function loop(time) {
            if (!timeStart) {
                timeStart = time;
            }

            let timeElapsed = time - timeStart;

            if (elt === window) {
                window.scrollTo(0, ease(timeElapsed, start, distance, duration))
            } else {
                elt.scrollTop = ease(timeElapsed, start, distance, duration);
            }


            if (timeElapsed < duration) {
                requestAnimationFrame(loop)
            } else {
                if (elt === window) {
                    window.scrollTo(0, destination)
                } else {
                    elt.scrollTop = destination;
                }
                timeStart = false;
                if (fb) {
                    fb(elt);
                }
                tools.releaseNav();
            }
        }

        proceed();

        function ease(t, b, c, d) {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b
        }
    },

    easeTo(start, target, action, duration = 300) {
        if (start && target && action) {
            let distance, timeStart;

            function proceed() {
                distance = target - start;
                timeStart = null;
                requestAnimationFrame(loop);
            }


            function loop(time) {
                if (!timeStart) {
                    timeStart = time;
                }
                let timeElapsed = time - timeStart;
                //todo: result rounding as param
                action(Math.round(ease(timeElapsed, start, distance, duration)));
                if (timeElapsed < duration) {
                    requestAnimationFrame(loop)
                } else {
                    action(target);
                    timeStart = false;
                }
            }

            proceed();

            function ease(t, b, c, d) {
                t /= d / 2;
                if (t < 1) return c / 2 * t * t + b;
                t--;
                return -c / 2 * (t * (t - 2) - 1) + b
            }
        }
    },

    doOnWindowResize(action) {
        if (action && action.fn) {
            this.doOnWindowResizeList.push(action);
        }
    },

    onWindowResizeActions() {
        tools.each(this.doOnWindowResizeList, action => {
            action.fn(action.arg)
        });
    },

    updateHistory(url) {
        tools.updateFHistory(null, null, url)
    },

    updateFHistory(data, title, url,) {
        url = url.replace(/~.*~/, '');
        // url = encodeURI(url);
        window.history.pushState(data, title, url);
    },

    replaceHistoryState(url) {
        url = url.replace(/~.*~/, '');
        // url = encodeURI(url);
        history.replaceState(null, null, url);
    },

    navBlocked: false,
    navHidden: false,
    navElt: null,

    initNavigation(elt) {
        tools.navElt = elt;
        let scrollPos = 0;
        let lastScrollDown = false;


        window.addEventListener('scroll', () => {
            if(document.body.classList.contains('search')) {
                tools.showNav();

            } else if(!tools.navBlocked) {
                let currentPos = document.body.getBoundingClientRect().top;
                lastScrollDown = currentPos <= scrollPos;

                if (Math.abs(scrollPos - currentPos) > 50) {

                    if (scrollPos < -100 && lastScrollDown ) {
                        tools.hideNav();

                    } else if (!lastScrollDown) {
                        tools.showNav();
                    }
                    scrollPos = currentPos;
                }
            }
        })
    },

    hideNav(andBlock) {
        tools.navBlocked = false;
        if(!tools.navHidden) {
            tools.navElt.style.transform = 'translateY(-100%)';
            tools.navHidden = true;
            document.body.classList.add("hideNav")
            document.getElementById("shoppingCart")?.classList.remove("show")
        }
        if(andBlock) {
            tools.navBlocked = true;
        }
    },

    showNav() {
        tools.navBlocked = false;
        if(tools.navHidden) {
            tools.navElt.style.transform = 'translateY(0)';
            tools.navHidden = false;
            document.body.classList.remove("hideNav")
        }
    },

    freezeNav() {
        tools.navBlocked = true;
    },

    releaseNav() {
        setTimeout(()=>tools.navBlocked = false, 200);
    }
};

module.exports = tools;
