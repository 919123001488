import Handler from "./Handler";

class DefaultHandler extends Handler {
    constructor() {
        super();
    }

    init() {}
    update(){}
}

export default DefaultHandler;
