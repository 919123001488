const tools = require('../tools');
import Filter from "./Filter";
import Selection from "./Selection";
// import SimpleFilter from "./SimpleFilter"
class FilterForm {

    constructor(elt, list) {
        this.elt = elt;
        this.list = list;
        // this.url = this.elt.getAttribute('action');
        this.formData = new FormData();
        this.filters = [];
        this.init();
    }

    init() {
        tools.each(this.elt.querySelectorAll('.filter'), filter =>
            {
                if (filter.classList.contains('selection')) {
                    this.filters.push(new Selection(filter, this))
                } else {
                    this.filters.push(new Filter(filter, this))
                }

            }
        );
        this.initListeners();
    }

    initListeners() {
        const buttonClick = (e, filter) => {
            e.preventDefault();
            e.stopPropagation();
            if(!filter.isOpen()) {
                tools.each(this.filters, f => f.close());
            }
            filter.toggleOpen();
        };

        tools.each(this.filters, filter => {
            if(filter.showButton) {
                filter.showButton.addEventListener('click', e => buttonClick(e, filter));
            }
        })
    }

    update() {
        this.formData = new FormData(this.elt);
        this.list.update();
    }
}

export default FilterForm;
