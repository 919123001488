const tools = require('../tools');

class Entry {
    constructor(id, elt, listWrapper, scrollableWrapper) {
        this.id = id;
        this.elt = elt;
        this.listWrapper = listWrapper;
        this.scrollableWrapper = scrollableWrapper ? scrollableWrapper : window;

        if(!this.elt.classList.contains('checklist-entry')) {
            this.wrapper = elt.querySelector('.entry-wrapper');
            this.paramValue = elt.getAttribute('data-param');
            this.bookBox = this.wrapper.querySelector('.book-box');
            this.info = this.wrapper.querySelector('.info');
            this.active = false;
            this.paramMatch = this.elt.classList.contains('param');
            this.initialized = false;
            tools.doOnWindowResize(
                {
                    arg: this,
                    fn: (arg) => arg.reInit()
                }
            );
        }
    }

    equals(otherEntry) {
        return this.id === otherEntry.id;
    }

    reInit() {
        if (this.initialized) {
            this.elt.style.height = '';
            this.elt.style.maxHeight = '';
            this.elt.classList.remove('active');
            setTimeout(() => {
                if (this.active) {
                    this.activate();
                }
            }, 0);
        }
    }

    init() {
        this.initialized = true;
        this.elt.style.height = this.wrapper.offsetHeight + 'px';
        this.elt.style.maxHeight = this.wrapper.offsetHeight + 'px';
    }

    activate(currentActiveEntries) {
        if (this.info) {
            if (currentActiveEntries) {
                tools.each(currentActiveEntries, cae => cae.hide());
            }

            if (!this.initialized) {
                this.init();
                setTimeout(() => this.activate(currentActiveEntries), 0);
            } else {
                this.active = true;
                this.elt.classList.add('active');
                this.elt.style.height = (this.elt.offsetHeight + this.info.offsetHeight) + 'px';
                this.elt.style.maxHeight = (this.elt.offsetHeight + this.info.offsetHeight) + 'px';
                // tools.replaceHistoryState(tools.getCurrentURL(true) + '?entry=' + this.paramValue);
                if(currentActiveEntries) {
                    let gap = 5;
                    if(!tools.navHidden) {
                        gap = tools.device.isLarge() ? 120 : 85;
                    }

                    let before = true;
                    let sameRow = false;
                    let prevInfoHeight = 0;

                    setTimeout(()=>{
                        tools.each(currentActiveEntries, cae => {
                            if(cae.elt.offsetTop < this.elt.offsetTop - 10) {
                                prevInfoHeight = cae.info.offsetHeight;
                                before = false;
                            } else if(cae.elt.offsetTop < this.elt.offsetTop + 10) {
                                sameRow = true;
                                before= false;
                            }
                            cae.deactivate();
                        });
                        if(before || sameRow) {
                            tools.scrollTo(this.scrollableWrapper, this.elt.offsetTop + this.listWrapper.offsetTop - gap);
                        } else {
                            tools.scrollTo(this.scrollableWrapper, this.elt.offsetTop + this.listWrapper.offsetTop - prevInfoHeight - gap, 200);
                        }
                    }, 200);

                }
            }
        }
    }

    hide() {
        this.elt.classList.remove('active');
    }

    deactivate() {
        if (this.active) {
            this.active = false;
            this.hide();
            this.elt.style.height = (this.wrapper.offsetHeight + 50) + 'px';
            this.elt.style.maxHeight = (this.wrapper.offsetHeight + 50) + 'px';
        }
    }

    sameRowAs(otherEntry) {
        return Math.abs(this.elt.getBoundingClientRect().top - otherEntry.elt.getBoundingClientRect().top < 20);
    }

}

export default Entry;
