/*
 * © 2021 Sukoa AG <Interactive Technologies> http://www.sukoa.com
 */

import DataLayerEvent from "../DataLayerEvent";

class RemoveFromCartDL extends DataLayerEvent {
    constructor(item) {
        super();
        this.item = item;
    }

    generateParameters() {
        this.getParameters().event = 'remove_from_cart';
        this.getParameters().ecommerce = {
            items: [this.item]
        };
    }

}

export default RemoveFromCartDL;
