/*
 * © 2021 Sukoa AG <Interactive Technologies> http://www.sukoa.com
 */

import tools from "./tools";
import AddToCartDL from "../../../../../diogenes-module/src/main/webpack/js/datalayer/ecommerce/AddToCartDL";
import RemoveFromCartDL from "./datalayer/ecommerce/RemoveFromCartDL";
import ViewCartDL from "./datalayer/ecommerce/ViewCartDL";

function isShoppingCartPage() {
    return document.querySelector(".shoppingCart-component") !== null;
}

function showLoading(showInOverlay) {
    let loading;
    if (showInOverlay) {
        loading = document.querySelector('#shoppingCart .shop-overlay-loading');
    } else {
        loading = document.querySelector('body > .shop-overlay-loading');
    }

    loading.classList.add("loading");
    return loading;
}


function hideLoading(showInOverlay) {
    let loading;
    if (showInOverlay) {
        loading = document.querySelector('#shoppingCart .shop-overlay-loading');
    } else {
        loading = document.querySelector('body > .shop-overlay-loading');
    }
    loading.classList.remove("loading");
}

function updateData(response) {
    let parentElement = document.getElementById("shoppingCart").querySelector(".shoppingCartData").parentElement;
    let parentElement2 = document.getElementById("shoppingCart").querySelector(".shoppingCartOverlay");
    parentElement.replaceChild(response.querySelector(".shoppingCartData"), document.getElementById("shoppingCart").querySelector(".shoppingCartData"))
    parentElement2.replaceChild(response.querySelector(".shoppingCartOverlay .items"), parentElement2.querySelector(".items"))
}

function showInCart(response) {
    tools.each(document.querySelectorAll("article.title.isincart"), e => {
        e.classList.remove('isincart')
    })

    tools.each(response.querySelectorAll(".shoppingCartOverlay .item[data-ean]"),
        element => {
            let ean = element.dataset.ean;
            let book = document.querySelector('article.title[data-ean="' + ean + '"]');
            if (book) {
                book.classList.add('isincart')
            }
        }
    )

}

class ShoppingCart {
    constructor() {
        let shoppingCartElt = document.getElementById('shoppingCart');

        if (shoppingCartElt) {
            this.shoppingCartElt = shoppingCartElt;
            this.init()
        }
    }

    init() {

        let _this = this;

        this.shoppingCartElt.addEventListener('mouseenter', (evt) => {
            // if (!event.target.matches(".shoppingCartData")) return;
            if (!tools.device.isSmall() && !isShoppingCartPage()) {
                this.shoppingCartElt.classList.add("show")
                this.shoppingCartElt.classList.add("mouse")

                try {
                    let ecommerce = _this.buildCartDataLayer(_this.shoppingCartElt);
                    if(ecommerce) {
                        _this.sendDataLayer(new ViewCartDL(ecommerce))
                    }
                } catch (e) {
                    console.log(e)
                }

            }

        })

        this.shoppingCartElt.addEventListener('mouseleave', (evt) => {
            if (this.shoppingCartElt.classList.contains("mouse")) {
                this.shoppingCartElt.classList.remove("show")
                this.shoppingCartElt.classList.remove("mouse")
                this.shoppingCartElt.classList.remove("error")
            }
        })

        //Close Overlay on click outside
        document.addEventListener('click', event => {
            if (!_this.shoppingCartElt.contains(event.target)) {
                _this.shoppingCartElt.classList.remove("show")
                _this.shoppingCartElt.classList.remove("error")
            }
        })

        //Close Overlay on Close-click
        document.addEventListener('click', event => {
            const element = matchPath(event.target, "#shoppingCart .close");
            if (element) {
                _this.shoppingCartElt.classList.remove("show")
                _this.shoppingCartElt.classList.remove("error")
            }
        })

        //Add button
        document.addEventListener('click', evt => {
            const element = matchPath(evt.target, ".add-cart-button:not(.disabled)");
            // if (!evt.target.matches(".add-cart-button:not(.disabled)")) return;

            if (element) {
                let shoppingCartComponent = evt.target.closest('.shoppingCart-component');
                let titleDetailElement = evt.target.closest('.title-detail-content');
                let listDisplay = evt.target.closest('.list-component.titles-list')

                let loading = showLoading((shoppingCartComponent || titleDetailElement || listDisplay) === null)

                let url = window.location.href
                url = url.replace(window.location.hash, "")
                if (url.indexOf("?") > 0) {
                    url += "&"
                } else {
                    url += "?"
                }
                url += "cmd=shoppingcart.add&ean=" + element.dataset.ean + "&quantity=1&ck=" + new Date().getTime()

                _this.sendDataLayer(new AddToCartDL(
                    {
                        items: [
                            {
                                // item_name: titleDetailElement.dataset.title,
                                item_id: element.dataset.ean,
                                // price: titleDetailElement.dataset.priceDe,
                                // item_variant: titleDetailElement.dataset.variant,
                                quantity: 1
                            }
                        ]
                    }
                ))

                let xmlHttpRequest = tools.ajxGet(url, null, (response) => {
                    tools.showNav();
                    showInCart(response)
                    updateData(response)

                    if (shoppingCartComponent !== null) {
                        shoppingCartComponent.replaceChild(response.querySelector(".shoppingCart-component .wrapper"), shoppingCartComponent.querySelector('.wrapper'))
                    }

                    if (document.querySelector('#shoppingCart .item[data-ean="' + evt.target.dataset.ean + '"]') !== null) {
                        let element = document.querySelector('article.title[data-ean="' + evt.target.dataset.ean + '"]');
                        if (element) {
                            element.classList.add('isincart')
                        }
                    }

                    if (tools.device.isSmall()) {
                        // Reload added animation
                        loading.style.backgroundImage = "url(" + loading.dataset.addedAnimation + "?cb=" + Date.now() + ")"
                        setTimeout(() => {
                            hideLoading((shoppingCartComponent || titleDetailElement || listDisplay) === null)
                            setTimeout(() => {
                                loading.style.backgroundImage = "url(" + loading.dataset.loadingAnimation + ")"
                            }, 500)
                        }, 2000)
                    } else {
                        if (!_this.shoppingCartElt.classList.contains("show") && shoppingCartComponent === null) {
                            tools.showNav()
                            _this.shoppingCartElt.classList.add("show")
                        }
                        hideLoading((shoppingCartComponent || titleDetailElement || listDisplay) === null)

                    }
                }, 'document', false,
                    (response) => {
                        console.log("Error happened " + response)

                        if (tools.device.isSmall()) {
                            // Reload added animation
                            loading.style.backgroundImage = "url(" + loading.dataset.addedAnimation + "?cb=" + Date.now() + ")"
                            setTimeout(() => {
                                hideLoading((shoppingCartComponent || titleDetailElement || listDisplay) === null)
                                setTimeout(() => {
                                    loading.style.backgroundImage = "url(" + loading.dataset.loadingAnimation + ")"
                                }, 500)
                            }, 2000)
                        } else {
                            if (!_this.shoppingCartElt.classList.contains("show") && shoppingCartComponent === null) {
                                tools.showNav()
                                _this.shoppingCartElt.classList.add("show")
                                _this.shoppingCartElt.classList.add("error")
                            }
                            hideLoading((shoppingCartComponent || titleDetailElement || listDisplay) === null)
                        }

                    });
            }
        })


        //Substract button
        document.addEventListener('click', evt => {
            const element = matchPath(evt.target, ".sub-cart-button:not(.disabled)");

            if (element) {
                let shoppingCartComponent = evt.target.closest('.shoppingCart-component');

                showLoading(shoppingCartComponent === null);

                let url = window.location.href
                url = url.replace(window.location.hash, "")
                if (url.indexOf("?") > 0) {
                    url += "&"
                } else {
                    url += "?"
                }
                url += "cmd=shoppingcart.sub&ean=" + evt.target.dataset.ean + "&quantity=1&ck=" + new Date().getTime()

                tools.ajxGet(url, null, (response) => {
                    showInCart(response)
                    updateData(response)

                    if (shoppingCartComponent !== null) {
                        shoppingCartComponent.replaceChild(response.querySelector(".shoppingCart-component .wrapper"), shoppingCartComponent.querySelector('.wrapper'))
                    }

                    _this.sendDataLayer(new RemoveFromCartDL(
                        [
                            {
                                // item_name: titleDetailElement.dataset.title,
                                item_id: element.dataset.ean,
                                // price: titleDetailElement.dataset.priceDe,
                                // item_variant: titleDetailElement.dataset.variant,
                                quantity: 1
                            }
                        ]
                    ))

                    hideLoading(shoppingCartComponent === null);
                }, 'document', false)
            }
        })


        //Remove button
        document.addEventListener('click', evt => {
            const element = matchPath(evt.target, ".remove-cart-button");
            const wrapper = !!element ? element.parentElement : null;

            if (element) {
                let shoppingCartComponent = element.closest('.shoppingCart-component');
                let oldVal = parseInt(element.parentElement.querySelector(".input_quantity").value)


                showLoading(shoppingCartComponent === null);

                let url = window.location.href
                url = url.replace(window.location.hash, "")
                if (url.indexOf("?") > 0) {
                    url += "&"
                } else {
                    url += "?"
                }
                url += "cmd=shoppingcart.remove&ean=" + element.dataset.ean + "&quantity=1&ck=" + new Date().getTime()

                tools.ajxGet(url, null, (response) => {
                    showInCart(response)
                    updateData(response)

                    if (shoppingCartComponent !== null) {
                        shoppingCartComponent.replaceChild(response.querySelector(".shoppingCart-component .wrapper"), shoppingCartComponent.querySelector('.wrapper'))
                    }

                    _this.sendDataLayer(new RemoveFromCartDL(
                        {
                            // item_name: titleDetailElement.dataset.title,
                            item_id: element.dataset.ean,
                            // price: titleDetailElement.dataset.priceDe,
                            // item_variant: titleDetailElement.dataset.variant,
                            quantity: oldVal
                        }
                    ))

                    hideLoading(shoppingCartComponent === null);
                }, 'document', false)
            }
        })

        document.addEventListener('change', evt => {
                const element = matchPath(evt.target, ".input_quantity");
                const wrapper = !!element ? element.parentElement : null;

                if (element) {
                    let shoppingCartComponent = evt.target.closest('.shoppingCart-component');
                    let titleDetailElement = evt.target.closest('.title-detail-content');

                    showLoading((shoppingCartComponent || titleDetailElement) === null)


                    let url = window.location.href
                    url = url.replace(window.location.hash, "")
                    if (url.indexOf("?") > 0) {
                        url += "&"
                    } else {
                        url += "?"
                    }
                    url += "cmd=shoppingcart.set&ean=" + evt.target.closest('.item').dataset.ean + "&quantity=" + evt.target.value + "&ck=" + new Date().getTime()


                    tools.ajxGet(url, null, (response) => {
                        showInCart(response)
                        updateData(response)

                        if (shoppingCartComponent !== null) {
                            shoppingCartComponent.replaceChild(response.querySelector(".shoppingCart-component .wrapper"), shoppingCartComponent.querySelector('.wrapper'))
                        }

                        if (document.querySelector('#shoppingCart .item[data-ean="' + evt.target.dataset.ean + '"]') !== null) {
                            // $('.title-icon[data-ean="' + evt.currentTarget.dataset.ean + '"]').addClass('isincart')
                        }

                        hideLoading((shoppingCartComponent || titleDetailElement) === null)
                        if (tools.device.isSmall()) {
                            //show gif
                            loading[0].style.backgroundImage = "url(" + loading[0].dataset.addedAnimation + "?cb=" + Date.now() + ")"
                            // setTimeout(() => {
                            //     loading.fadeOut("fast");
                            //     loading[0].style.backgroundImage= "url("+loading[0].dataset.loadingAnimation+")"
                            // } , 2000)
                        } else {
                            if (!_this.shoppingCartElt.classList.contains("show") && shoppingCartComponent === null) {
                                _this.shoppingCartElt.classList.add("show")
                            }
                        }

                    }, 'document', false)

                }
            })


        let url = window.location.href
        url = url.replace(window.location.hash, "")
        if (url.indexOf("?") > 0) {
            url += "&"
        } else {
            url += "?"
        }
        url += "cmd=shoppingcart.count&ck=" + new Date().getTime()

        tools.ajxGet(url,
            null,
            (response) => {
                showInCart(response)
                updateData(response)
            },
            'document',
            false
        )

        function matchPath(element, selector) {
            if (element) {
                if (element.matches(selector)) {
                    return element;
                }
                return matchPath(element.parentElement, selector);
            }
            return null;
        }
    }

    sendDataLayer(datalayerEvent) {
        tools.executeDataLayerBy('default', datalayerEvent, new Event("DLEvent"));
    }

    buildCartDataLayer(shoppingCartElt) {
        if (shoppingCartElt) {
            const item = shoppingCartElt.querySelector(".item[data-price-total]");
            if (item) {
                const ecommerce = {
                    currency: 'EUR',
                    value: parseFloat(item.dataset.priceTotal),
                    items: []
                }

                shoppingCartElt.querySelectorAll(".item[data-ean]").forEach(item => {
                    let shoppingCartItem = {
                        item_name: item.dataset.title,
                        item_id: item.dataset.ean,
                        price: parseFloat(item.dataset.price),
                        // item_variant: titleDetailElement.dataset.variant,
                        quantity: parseFloat(item.querySelector("input.input_quantity").value)
                    };

                    ecommerce.items.push(shoppingCartItem)
                })
                return ecommerce
            }
        }
    }

}

export default ShoppingCart;
