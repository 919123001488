const tools = require('../tools');
import Filter from "./Filter";
class Selection extends Filter {
    constructor(elt, form) {
        super(elt, form);
        this.button = this.elt.querySelector('button');
        this.selectedLabel = this.elt.querySelector('.selected-label');
        this.dropdown = this.elt.querySelector('.selection');
        super.init();
        this.init();
    }

    init() {
        if (this.button) {
            this.button.addEventListener('click', e => {
                    e.stopPropagation();
                    e.preventDefault();
                    if(this.elt.classList.contains('open')) {
                        this.closeSelection();
                    } else {
                        this.openSelection();
                    }
                }
            );
        }
    }

    openSelection() {
        this.elt.classList.add('open');
    }

    closeSelection() {
        this.elt.classList.remove('open')
    }

    getOptionClickAction(e, option) {
        e.stopPropagation();

        if(!option.active) {
            tools.each(this.options, opt => opt.deactivate());
            option.activate();
            this.selectedLabel.textContent = option.label.getAttribute('data-label');
            setTimeout(() => {
                this.update();
            }, 100);
        }
        this.closeSelection();

    }
}

export default Selection;
