

class Option {

    constructor(elt) {
        this.elt = elt;
        this.label = this.elt.querySelector('label');
        this.input = this.elt.querySelector('input');
        this.active = this.elt.classList.contains('active');

        // this.checkLabel();
    }

    toggleActiveState() {
        if(this.active) {
            this.deactivate();
        } else {
            this.activate();
        }
    }

    deactivate() {
        this.active = false;
        this.elt.classList.remove('active');
    }

    activate() {
        this.active = true;
        this.elt.classList.add('active');
    }

    isActive() {
        return this.active;
    }
}

export default Option;
