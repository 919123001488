const tools = require('../tools');
import Option from "./Option";
// import Selection from "./Selection";
class Filter {

    constructor(elt, form) {
        this.elt = elt;
        this.form = form;
        this.showButton = this.elt.querySelector('.show-btn');
        this.optionsElt = this.elt.querySelector('.option-list');
        this.options = [];
        this.fullHeight = null;
        this.closedHeight = null;
        this.visible = false;
        this.closeTimeout = null;
        this.activeNbText = this.elt.querySelector('.active-nb');
        this.init()
    }

    init() {
        const arg = this;
        const fct = (item) => {item.getSize();};
        setTimeout(() => arg.getSize(), 500);
        tools.doOnWindowResize({arg: arg, fn: fct});

        if(this.optionsElt) {

            tools.each(this.optionsElt.querySelectorAll('.option'), option => {
                const opt = new Option(option);
                opt.input.addEventListener('click', e => this.getOptionClickAction(e, opt));
                this.options.push(opt);
            });
        }
    }

    getOptionClickAction(e, option) {
        e.stopPropagation();
        option.toggleActiveState();
        setTimeout(() => {
            this.update();
        }, 100);

    }

    update() {
        const activeNb = this.options.reduce((a,b) => {
            if(b.active) {
                return ++a;
            } else {
                return a;
            }
        }, 0);

        if (this.activeNbText) {
            if(activeNb === 0) {
                this.activeNbText.textContent = '';
            } else {
                this.activeNbText.textContent = '(' + activeNb + ')';
            }
        }
        this.form.update();
    }

    getSize() {
        this.elt.style.height = 'auto';
        this.elt.style.maxHeight = 'none';
        this.closedHeight = this.elt.offsetHeight;
        if (this.optionsElt) {
            this.fullHeight = this.closedHeight + this.optionsElt.offsetHeight + 33;
            if(this.isOpen()) {
                this.setHeight(this.fullHeight + 'px');
            }
        }

        if(!this.isOpen()) {
            this.setHeight(this.closedHeight + 'px');
        }
    }

    toggleOpen() {
        if (this.isOpen()) {
            this.close(true);
        } else {
            this.open();
        }
    }

    open() {
        if(!this.isOpen()) {
            this.visible = true;
            this.elt.classList.add('open');
            if(this.closeTimeout) {
                window.clearTimeout(this.closeTimeout);
            }
            this.setHeight(this.fullHeight + 'px');
            return true;
        } else {
            return false;
        }
    }

    close() {
        if(this.isOpen()) {
            this.visible = false;
            this.elt.classList.remove('open');
            if(this.closeTimeout) {
                window.clearTimeout(this.closeTimeout);
            }
            this.closeTimeout = setTimeout(()=>this.setHeight(this.closedHeight + 'px'), 200)

            return true;
        } else {
            return false;
        }
    }

    setHeight(height) {
        if(null !== height) {
            this.elt.style.height = height;
            this.elt.style.maxHeight = height;
        }
    }

    udpateHeight() {
        if(this.isOpen()) {
            this.setHeight(this.fullHeight + 'px');
        } else {
            this.setHeight(this.closedHeight + 'px');
        }
    }

    isOpen() {
        return this.visible;
    }
}

export default Filter;
