/*
 * © 2021 Sukoa AG <Interactive Technologies> http://www.sukoa.com
 */

import DataLayerEvent from "../DataLayerEvent";

class AddToCartDL extends DataLayerEvent {
    constructor(ecommerce) {
        super();
        this.ecommerce = ecommerce;
    }

    generateParameters() {
        this.getParameters().event = 'add_to_cart';
        this.getParameters().ecommerce = this.ecommerce;
    }
}

export default AddToCartDL;
