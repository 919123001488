// import {default as dataLayerHandler} from "../../../../../diogenes-module/src/main/webpack/js/datalayer/dataLayerHandler";
import {default as dataLayerHandler} from "./datalayer/dataLayerHandler";

const tools = require('./tools');
import Swiper from 'swiper'

import Device from './Device';
import Lazy from './Lazy';
import List from './list/List';
import Search from './Search';
import OverlaysManager from './OverlayManager';
import ShoppingCart from "./ShoppingCart";

const app = {
    init() {
        app.globalDomSettings();
        app.initSlider();
        app.initNavigation();
        app.initMenuButton();
        app.initSearchButton();
        app.lazyLoader();
        app.windowResizeEvent();
        app.initBuyButtons();
        app.initB2LFrame();
        app.initLists();
        app.globalListeners();
        app.initFooterNlSignup();
        OverlaysManager.initB2LButton();
        app.initPrint();
        app.initShoppingCart();
        app.dataLayer();

    },

    dataLayer() {

        // if(tools.hasParameter('notracking')) {
        //     new Cookie('notracking').set('1', 30);
        // }

        // if(!new Cookie('notracking').check('1') && window.dataLayer) {
        tools.initDataLayer(dataLayerHandler);
        // }
    },

    globalDomSettings() {
        document.documentElement.classList.remove('no-js');
        tools.device = new Device();
        tools.doOnWindowResize(()=>tools.device = new Device());

        if(!tools.device.bot) {
            let canonicalLink =document.querySelector('link[rel="canonical"]');
            if (canonicalLink) {
                canonicalLink.parentElement.removeChild(canonicalLink)
            }
        }
    },

    initNavigation() {
        const heading = document.querySelector('#heading');
        if(heading) {
            tools.initNavigation(heading);
        }
    },

    initMenuButton() {
        const btn = document.querySelector('#menu-btn');
        if (btn) {
            btn.addEventListener('click', e => {
                if (document.body.classList.contains('navexp')) {
                    document.body.classList.remove('navexp');
                } else if (document.body.classList.contains('search')) {
                    document.body.classList.remove('search');
                } else {
                    document.body.classList.add('navexp');
                    OverlaysManager.register(document.body, elt => elt.classList.remove('navexp'));
                }
            })
        }
    },

    initSearchButton() {
        new Search();
    },

    globalListeners() {
        document.addEventListener('keyup', e => {
            if (e.key === 'Escape') {
                OverlaysManager.closeLast();
            }
        })
    },

    initBuyButtons() {

        const buyBtnClickHandler = (e, btn, targetElt) => {
            if (targetElt.classList.contains('open')) {
                targetElt.classList.remove('open');

            } else {
                targetElt.classList.add('open');
                tools.hideNav(true);
                OverlaysManager.register(targetElt, elt => {
                    elt.classList.remove('open');
                    tools.showNav();
                });
            }
        };

        tools.each(document.querySelectorAll('.buy-btn'), btn => {
            const targetId = btn.getAttribute('data-overlay');
            if (targetId) {
                const targetElt = document.querySelector('#' + targetId);
                if (targetElt) {
                    btn.addEventListener('click', e => buyBtnClickHandler(e, btn, targetElt));
                }
            }
        });

    },

    initShoppingCart() {
        new ShoppingCart();
    },

    lazyLoader() {
        new Lazy()
    },

    initLists() {
        tools.each(document.querySelectorAll('#wrapper .list-component'), list => {
            let listObj = new List(list);
            listObj.onUpdate(OverlaysManager.initB2LButton);
        })
    },

    windowResizeEvent() {
        let timer = null;

        window.onresize = (evt) => {
            if (timer) {
                window.clearTimeout(timer);
            }
            timer = window.setTimeout(() => {
                tools.onWindowResizeActions()
            }, 500);
        }
    },

    initSlider() {


        tools.each(document.querySelectorAll('.slider-cp .swiper-container'), slider => {

            new Swiper(slider, {
                speed: 400,
                spaceBetween: 50,
                slidesPerView: 'auto',
                navigation: {
                    nextEl: slider.querySelector('.slider-next'),
                    prevEl: slider.querySelector('.slider-prev')
                },

                centerInsufficientSlides: true,

                breakpoints: {
                    320: {
                        centeredSlides: true
                    },

                    640: {
                        centeredSlides: false
                    },

                    1000: {
                        setWrapperSize: true
                    }
                }
            })
        });

        tools.each(document.querySelectorAll('.author-texts-slider'), sliderWrapper => {
            const slider = sliderWrapper.querySelectorAll('.swiper-container');
                new Swiper(slider, {
                    speed: 400,
                    centerInsufficientSlides: true,
                    navigation: {
                        nextEl: sliderWrapper.querySelector('.slider-next'),
                        prevEl: sliderWrapper.querySelector('.slider-prev')
                    },
                    breakpoints: {
                        320: {
                            slidesPerView: 1,
                        },

                        550: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },

                        750: {
                            slidesPerView: 3,
                            spaceBetween: 10,

                        },

                        950: {
                            slidesPerView: 4,
                        }
                    },
                    // centeredSlides: slider.classList.contains('center')
                })
            });
    },



    initB2LFrame() {
        let marketingEnabled = false
        if (Cookiebot !== undefined && Cookiebot.consent !== undefined && Cookiebot.consent.marketing) {
            marketingEnabled = Cookiebot.consent.marketing
        }
        if (marketingEnabled) {
            const b2lFrames = document.querySelectorAll('.b2l-frame');
            if (b2lFrames.length > 0) {
                if (IntersectionObserver) {
                    const callback = (entries, observer) => {
                        entries.forEach(entry => {
                            if (entry.isIntersecting) {
                                observer.disconnect();
                                entry.target.src = entry.target.getAttribute('data-src')
                            }
                        })
                    };

                    const observer = new IntersectionObserver(callback, {threshold: 0.7});
                    tools.each(b2lFrames, frame => {
                        const iframeSrc = frame.getAttribute('data-src');
                        if (iframeSrc) {
                            observer.observe(frame);
                        }
                    })

                } else {
                    tools.each(b2lFrames, frame => {
                        const iframeSrc = frame.getAttribute('data-src');
                        if (iframeSrc) {
                            setTimeout(() => {
                                frame.src = iframeSrc
                            }, 1000);
                        }
                    });
                }
            }
        } else {
            window.addEventListener('CookiebotOnAccept', function (e) {
                let marketingEnabled = false
                if (Cookiebot !== undefined && Cookiebot.consent !== undefined && Cookiebot.consent.marketing) {
                    marketingEnabled = Cookiebot.consent.marketing
                }
                if (marketingEnabled) {
                    app.initB2LFrame()
                }
            }, false);
        }
    },


    initFooterNlSignup() {
        const signup = document.querySelector('#f-nlsignup');
        if (signup) {
            const signupWrapper = signup.querySelector('.signup');
            if (signupWrapper) {
                const emailInput = signupWrapper.querySelector('.email');
                emailInput.onfocus = (e) => {
                    signupWrapper.classList.add('editing');

                    (function(d, s, id){
                        var js, fjs = d.getElementsByTagName(s)[0];
                        if (d.getElementById(id)){ return; }
                        js = d.createElement(s); js.id = id;
                        js.onload = function(){
                            // remote script has loaded
                        };
                        const form = signup.querySelector("form")
                        js.src = "https://www.google.com/recaptcha/api.js?onload=greload"+form.dataset.uuid+"&render=explicit&hl=en";
                        fjs.parentNode.insertBefore(js, fjs);
                    }(document, 'script', 'grecaptcha'));

                    emailInput.onfocus = null;
                    if (!tools.device.isTouch()) {
                        tools.scrollTo(window, signup.offsetTop + 100, 700)
                    }
                };
            }

            const signupContent = signup.querySelector('.newslettersubscription');
            const nlSignupForm = signup.querySelector('#newsletter-form');
            const nlSingupBtn = signup.querySelector('#submit-nl-signup');

            if(signupContent && nlSignupForm && nlSingupBtn) {

                nlSignupForm.addEventListener('submit', e => {
                    e.preventDefault();
                    signupContent.classList.add('transparentize');
                    let url = tools.getCurrentURL(true);
                    url = url.replace('.html', '');
                    url += '~mgnlArea=nlSignup~.html';

                    let recaptcha= nlSignupForm.dataset.recaptcha
                    let recaptchaEnabled = recaptcha !== undefined
                    if (recaptchaEnabled) {
                        let actionName = nlSignupForm.dataset.recaptchaAction
                        if (!actionName) {
                            actionName = 'submit'
                        }

                        let renderId = nlSignupForm.dataset.recaptchaId
                        grecaptcha.ready(function () {
                            grecaptcha.execute(renderId, {action: actionName}).then(function (token) {
                                // Add your logic to submit to your backend server here.
                                let input = document.createElement("input");
                                input.setAttribute("type", "hidden");
                                input.setAttribute("name", "grToken");
                                input.setAttribute("value", token);
                                nlSignupForm.appendChild(input);

                                tools.ajxGet(url, new FormData(nlSignupForm), (response) => {
                                    signupContent.innerHTML = response.querySelector('.newslettersubscription').innerHTML;
                                    signupContent.classList.remove('transparentize');
                                }, 'document', false)
                            }).catch(function (error) {
                                console.warn("Error " + error)
                            });
                        })
                    } else {
                        tools.ajxGet(url, new FormData(nlSignupForm), (response) => {
                            signupContent.innerHTML = response.querySelector('.newslettersubscription').innerHTML;
                            signupContent.classList.remove('transparentize');
                        }, 'document', false)
                    }
                })
            }
        }
    },

    initPrint() {
        const printBtn = document.querySelector('#print-btn');
        if(printBtn) {
            printBtn.addEventListener('click', e => {
               window.print();
            });
        }
    },

};

// mail decrypt
function dcmadr(nnnn) {
    let a = "";
    for (let i = 0; i < nnnn.length; i++) {
        if (i % 3 === 0) {
            a += String.fromCharCode(nnnn.substr(i, 3));
        }
    }
    location.href = (a);
}

window.dcmadr = dcmadr;

document.addEventListener('DOMContentLoaded', () => {
    app.init();
});


