class DataLayerEvent {

    constructor() {
        this.parameters = {};
    }

    execute(e) {
        this.generateParameters();
        this.pushDataLayer();
    }

    generateParameters() {
        throw new Error('should not be executed directly')
    }

    pushDataLayer() {
        if (this.parameters.event) {
            dataLayer.push(this.parameters);
            if(window.debugdl) {
                console.log(this.parameters);
            }
        }
    }

    getParameters() {
        return this.parameters;
    }

}

export default DataLayerEvent;
