const tools = require('./tools');

class Overlay {
    constructor(elt, closeFn) {
        this.elt = elt;
        this.closeFn = closeFn;
        this.id;
        this.terminated = false;
    }

    equals(otherOverlay) {
        return otherOverlay.id === this.id;
    }

    init(id) {
        this.id = id;
        if(this.elt) {
            this.terminated = false;
            this.elt.classList.add('overlay-open');
            tools.each(this.elt.querySelectorAll('.close'), closeElt => closeElt.addEventListener('click', ()=>{
                    this.terminate();
                }
            ));
            this.elt.addEventListener('click', e => {
                if(e.target.classList.contains('overlay-open')) {
                    e.stopPropagation();
                    this.terminate();
                }
            });
        }
    }

    terminate() {
        if(!this.terminated) {
            if(this.elt) {
                this.elt.classList.remove('overlay-open');
            }
            this.closeFn(this.elt);
            this.terminated = true;
        }
    }
}


class Overlays {
    constructor() {
        this.idCount = 0;
        this.overlays = [];
    }

    register(overlay) {
        if(overlay instanceof Overlay) {
            this.overlays.push(overlay);
            overlay.init(this.idCount++);
        }
    }

    clear() {
        for(let i in this.overlays) {
            this.overlays[i].terminate();
        }
        this.overlays = [];
    }

    closeOverlay(overlay) {
        if(overlay instanceof Overlay) {
            let index = 0;
            let foundOverlay = null;
            for(; index < this.overlays.length; index++) {
                if(overlay.equals(this.overlays[index])) {
                    foundOverlay = this.overlays[index];
                    break;
                }
            }


            if(foundOverlay) {
                foundOverlay.terminate();
                this.overlays.splice(index, 1);
            }
        }
    }

    closeLast() {
        if(this.overlays.length > 0) {
            this.overlays.pop().terminate();
        }
    }
}

const OverlaysManager = {
    overlays: new Overlays(),
    loadingTimeout: null,
    showLoadingAnim() {
        if(!OverlaysManager.loadingTimeout) {
            document.body.classList.add('loading');
            OverlaysManager.loadingTimeout = window.setTimeout(()=>{OverlaysManager.removeLoadingAnim()}, 15000)
        }
    },
    removeLoadingAnim() {
        if(OverlaysManager.loadingTimeout) {
            OverlaysManager.loadingTimeout = null;
            document.body.classList.remove('loading');
        }
    },
    registerOverlay(overlay) {OverlaysManager.overlays.register(overlay)},
    closeOverlay(overlay) {OverlaysManager.overlays.closeOverlay(overlay)},

    register(elt, closeFn) {OverlaysManager.overlays.register(new Overlay(elt, closeFn))},
    clear() {OverlaysManager.overlays.clear()},
    closeLast(){OverlaysManager.overlays.closeLast()},

    initB2LButton(wrapElt) {
        const wrapperElt = wrapElt ? wrapElt : document;
        const b2lButtonsClickListener = (event, btn) => {
            event.stopPropagation();
            const b2lUrl = btn.getAttribute('data-url');

            if (b2lUrl) {
                const wrapper = document.createElement('div');
                wrapper.classList.add('b2l-overlay');
                wrapper.classList.add('overlay');

                wrapper.innerHTML =
                    '<div class="b2l-wrapper">' +
                    '<button class="close"><svg xmlns="http://www.w3.org/2000/svg" width="22.108" height="22.108" viewBox="0 0 22.108 22.108"><path d="M20.159,22.281,1.053,3.175A1.5,1.5,0,0,1,1.06,1.061a1.48,1.48,0,0,1,2.092-.03l.023.023L22.281,20.16a1.5,1.5,0,1,1-2.122,2.121Z" transform="translate(-0.612 -0.612)" fill="#ffffff"/><path d="M22.281,3.175,3.175,22.281a1.5,1.5,0,0,1-2.114-.007,1.48,1.48,0,0,1-.03-2.092l.023-.023L20.16,1.053a1.5,1.5,0,1,1,2.121,2.122Z" transform="translate(-0.612 -0.612)"  fill="#ffffff"/></svg></button>' +
                    '    <div class="sizer"></div>' +
                    '    <iframe src="' + b2lUrl + '"></iframe>' +
                    '</div>';

                document.body.insertAdjacentElement("beforeend", wrapper);
                OverlaysManager.register(wrapper, (elt) => elt.parentNode.removeChild(elt));
            }
        };

        const b2lButtons = wrapperElt.querySelectorAll('.b2l-btn');
        tools.each(b2lButtons, btn => btn.addEventListener('click', e => b2lButtonsClickListener(e, btn)));

    },
};
export {Overlay};
export default OverlaysManager;
