const tools = require('../../tools');
import DataLayerEvent from "../DataLayerEvent";
import ItemWrapper from "../ItemWrapper";

class ViewItemDL extends DataLayerEvent {
    static event_name = 'view_item';
    static maxCategoryNb = 6;

    constructor(elt) {
        super();
        this.titleDetailElt = elt;
        this.addToCartButton = null;
        this.buyButton = null;
        this.item = new ItemWrapper();
        this.ViewListDL = null;

        this.init();
    }

    init() {
        if (this.titleDetailElt
            && this.titleDetailElt.dataset.title
            && this.titleDetailElt.dataset.ean
            && this.titleDetailElt.dataset.priceDe) {

            this.addToCartButton = this.titleDetailElt.querySelector('.add-cart-button'); //if doesn't exists -> not in ecommerce, dataLayer not sent
            this.buyButton = this.titleDetailElt.querySelector('.buy-button'); //if doesn't exists -> not in ecommerce, dataLayer not sent

            this.getParameters().ecommerce = {items: []};
            if (this.addToCartButton || this.buyButton) {

                this.item.setId(this.titleDetailElt.dataset.ean);
                this.item.setName(this.titleDetailElt.dataset.title);
                this.item.setPrice(this.titleDetailElt.dataset.priceDe);
            }
        }
    }


    setViewListDL(ViewListDL) {
        this.ViewListDL = ViewListDL;
    }

    generateParameters() {
        this.getParameters().event = ViewItemDL.event_name;
        if (this.item) {

            const genre = this.titleDetailElt.dataset.genre;
            if (genre) {
                this.item.setCategories(genre.split('|'));
            }

            const variant = this.titleDetailElt.dataset.variant;
            if (variant) {
                this.item.setVariant(variant);
            }

            if (this.ViewListDL) {
                this.item.setListName(this.ViewListDL.getName());
                this.item.setListId(this.ViewListDL.getId());
            }

            const finalItem = this.item.getItem();
            this.getParameters().ecommerce.items.push(finalItem);
            this.getParameters().currency = finalItem.currency;
            this.getParameters().value = finalItem.price;

        }
    }
}

export default ViewItemDL;
