const tools = require('./tools');

class Lazy {
    constructor() {
        this.lim = 20;
        this.sim = 0;
        this.elementList = [];

        tools.each(document.querySelectorAll('.lazy'),
            elt => {
                this.elementList.push(elt)
            }
        );
        this.intersectionObserver = null;

        this.init();
    }

    init() {
        if (IntersectionObserver) {
            this.proceedWithObserver();
        } else {
            this.loadNext();
        }
    }

    increaseSim() {
        if(this.sim < this.lim ) {
            this.sim++;
            this.loadNext();
        }
    }

    reduceSim() {
        if(this.sim > 0) {
            this.sim--;
        }
        this.loadNext();
    }

    loadNext() {
        if(this.elementList.length > 0) {
            const elt = this.elementList.shift();

            const src = elt.getAttribute('data-src');

            if(src) {
               this.showImage(elt, src)
            } else {
                this.getParentWithClass(elt, 'lazy-wrapper').classList.add('ready');
                this.reduceSim()
            }

            this.increaseSim();
        }
    }

    showImage(elt, src) {
        if(!elt.lazyLoaded) {
            elt.lazyLoaded = true;
            const img = document.createElement('img');
            const self = this;
            img.onload = function() {
                elt.src = src;
                elt.classList.add('loaded');
                self.getParentWithClass(elt, 'lazy-wrapper').classList.add('ready');
                self.reduceSim();
            };

            img.src = src;
        }
    }

    getParentWithClass(elt, className, steps=3) {
        let parent = elt;
        for(let i = 0; i < steps; i++) {
            parent = parent.parentElement;
            if(parent.classList.contains(className)) {
                break;
            }
        }

        return parent;
    }


    proceedWithObserver() {
        const callback = (entries, observer)  => {
            entries.forEach(entry => {
                if(entry.isIntersecting) {
                    observer.unobserve(entry.target);
                    this.showImage(entry.target, entry.target.getAttribute('data-src'));
                }
            })
        };

        this.intersectionObserver = new IntersectionObserver(callback, {threshold: 0.05});

        tools.each(this.elementList, elt => this.intersectionObserver.observe(elt));
    }
}

export default Lazy;
