import tools from "../../tools";
import Handler from "../Handler";
import ViewItemDL from "./ViewItemDL";
import ViewListDL from "./ViewListDL";
import DataLayerEvent from "../DataLayerEvent";


class ViewsHandler extends Handler {

    constructor() {
        super();
        this.lists = [];
    }

    init() {
        const titleDetail = document.querySelector('.title-detail-content');
        if(titleDetail) {
            this.execute(new ViewItemDL(titleDetail), null);
        }

        const filterList = document.querySelector('.list-component.titles-list');
        if(filterList) {
            this.execute(new ViewListDL(filterList), null);
        }
    }

    execute(dle = new DataLayerEvent(), event) {
        if (dle instanceof ViewListDL) {
            this.lists.push(dle);
        } else if(dle instanceof ViewItemDL) {
            this.setListIfMatch(dle);
        }

        super.execute(dle, event);
    }

    setListIfMatch(dle= new ViewItemDL()) {
        const item = dle.item;
        if(item) {
            for(let i = 0; i < this.lists.length; i++) {
                const list = this.lists[i];
                if(list.matchWith(item)) {
                    dle.setViewListDL(list);
                    break;
                }
            }
        }
    }
}

export default ViewsHandler;
