class ItemWrapper {
    static maxCategoryNb = 6;

    constructor() {
        this.item = {}
        this.setCurrency();
        this.setBrand();
    }

    setId(id) {
        this.item.item_id = id;
    }

    setName(name) {
        this.item.item_name = name;
    }

    setCurrency(currency='EUR') {
        this.item.currency = currency
    }

    setBrand(brand='Diogenes') {
        this.item.item_brand = brand;
    }

    setPrice(price) {
        this.item.price = price;
    }

    setCategories(cats = [String()]) {
        let catNb = 0;
        cats.forEach(val => {
            if (catNb <= ItemWrapper.maxCategoryNb) {
                this.item['category' + (catNb > 0 ? catNb : '')] = val;
                catNb++;
            }
        })
    }

    setVariant(variant) {
        this.item.variant = variant;
    }

    setListName(name) {
        this.item.item_list_name = name;
    }

    setListId(id) {
        this.item.item_list_id = id;
    }

    getItem() {
        return this.item;
    }

    getItemId(){
        return this.getItem().item_id;
    }

    matchWith(item = new ItemWrapper()) {
        return this.getItemId() === item.getItemId();
    }
}

export default ItemWrapper;