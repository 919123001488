class Device {
    constructor() {
        this._width;
        this._height;
        this._touch = false;
        this._small = 749;
        this._medium = 1024;
        this.bot = false;
        this.init();
    }

    init() {
        this.bot = /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
        this.checkTouch();
        this.getHeightAndWidth();
    }

    getHeightAndWidth() {
        this._width = window.innerWidth;
        this._height = window.innerHeight;
    }

    checkTouch() {
        if (window.innerWidth <= this._small) {
            document.body.classList.add("touch");
            this._touch = true;
        } else {
            window.addEventListener('touchstart', function onFirstTouch() {
                document.body.classList.add("touch");
                window.removeEventListener('touchstart', onFirstTouch, {passive: true});
            }, {passive: true})
        }
    }

    getHeight() { return this._height;}
    getWidth() { return this._width;}
    isTouch() { return this._touch;}
    isSmall() {return this._small >= this._width;}
    isMedium() {return !this.isSmall() && this._medium >= this._width;}
    isLarge() {return this._medium < this._width;}
}

export default Device;
