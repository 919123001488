const tools = require('./tools');
import Lazy from "./Lazy";
import List from "./list/List";

import OverlaysManager from "./OverlayManager";
import {Overlay} from "./OverlayManager";

class Search {
    constructor() {
        this.searchAreaOverlay = document.querySelector('#search-area');
        this.init();
    }

    init() {
        this.initSearchOverlay();


        const searchSubmitBtn = document.querySelectorAll('.search-send');
        const resultsWrapper = document.querySelector('#search-results');
        const searchForm = document.querySelector('#search-form');
        const searchInput = searchForm.querySelector('#search-input-desktop');
        const mobSearchForm = document.querySelector('#search-form-mobile');
        const mobSearchInput = mobSearchForm.querySelector('#mobile-nav-search-text');
        if(searchSubmitBtn && resultsWrapper && searchForm) {

            let waitForResponse = false;
            tools.each(searchSubmitBtn, btn => btn.addEventListener('click', (e) => {
                e.preventDefault();
                const areaName = btn.getAttribute('data-area-name');
                let url = tools.getCurrentURL(true);
                if (areaName) {
                    url = url.substr(0, url.indexOf('.html')) + '~mgnlArea=' + areaName + '~.html';
                }

                if(document.body.classList.contains('navexp')) {
                    document.body.classList.remove('navexp');
                    document.body.classList.add('search');
                }

                if (!waitForResponse) {
                    waitForResponse = true;
                    OverlaysManager.showLoadingAnim();
                    resultsWrapper.innerHTML = '';


                    let formToSend;
                    if(btn.id === 'send-search-btn-mob') {
                        formToSend = mobSearchForm;
                        searchInput.value = mobSearchInput.value;
                    } else {
                        formToSend = searchForm;
                        mobSearchInput.value = searchInput.value;
                    }


                    tools.ajxGet(url, new FormData(formToSend), (response) => {
                        const results = response.querySelector('#search-results-wrapper');
                        if(results) {
                            this.searchAreaOverlay.classList.add('active');
                            resultsWrapper.insertAdjacentElement("afterbegin", results);
                            new Lazy();
                            this.initAnchorsLinks();
                            tools.each(resultsWrapper.querySelectorAll('.list-component'), list => this.initList(list));
                            OverlaysManager.initB2LButton(this.searchAreaOverlay);
                        }
                        waitForResponse = false;
                        OverlaysManager.removeLoadingAnim();

                    }, 'document', false);
                }
            }));
        }
    }

    initSearchOverlay() {
        const searchOverlay = new Overlay(document.body, elt => elt.classList.remove('search'));

        if (document.body.classList.contains('search')) {
            OverlaysManager.registerOverlay(searchOverlay);
            this.initAnchorsLinks();
            tools.each(this.searchAreaOverlay.querySelectorAll('.list-component'), list => this.initList(list))}

        tools.each(document.querySelectorAll('.search-btn'), btn => {
            btn.addEventListener('click', e => {

                if (document.body.classList.contains('search')) {
                    OverlaysManager.closeOverlay(searchOverlay);

                } else {
                    document.body.classList.add('search');
                    OverlaysManager.registerOverlay(searchOverlay);
                    const inputTargetId = btn.getAttribute('data-target');
                    if(inputTargetId) {
                        const inputTarget = document.querySelector(inputTargetId);
                        if(inputTarget) {
                            inputTarget.focus();
                        }
                    }
                }
            })
        });
    }

    initList(list) {
        new List(list, this.searchAreaOverlay);
    }

    initAnchorsLinks() {
        const anchorsLinks = document.querySelectorAll('a[href^="#"]');
        if(anchorsLinks) {
            const anchorLinkClickListener = (aLink) => {
                const target = document.querySelector(aLink.getAttribute('href'));
                if(target) {
                   aLink.addEventListener('click', e => {
                        tools.scrollTo(this.searchAreaOverlay, target.offsetTop)
                   })
                }
            };

            tools.each(anchorsLinks, aLink => {anchorLinkClickListener(aLink);})
        }
    }
}

export default Search;
